import React, {useState} from 'react';
import {Form, Button, Row, Col, Divider, Typography, Checkbox} from 'antd';
import {isMobile} from 'react-device-detect';
import {Link} from "react-router-dom"

import DeliveryDateTime from './deliveryDateTime';
import DeliveryAddress from './deliveryAddress';
import AdditionalInformation from './additionalInformation';
import PaymentMethod from './paymentMethod';
import TableItems from './tableItems';
import Containers from '../containers';
import DialogOrder from './../../../common/order/dialog'
import ContainersV1 from '../containersV1';

const {Title} = Typography;
const ComponentForm = ({
    addressId,
    cart,
    dataSourceContainers,
    containers,
    addedItems,
    handleSubmit,
    dataSource,
    totalSumContainers,
    totalSumItem,
    open, setOpened, handleContainersItems
}) => {
    if (cart.loading) return null;

    const [form] = Form.useForm();
    let initialValues = {
        deliveryAddressId: addressId ? addressId : null,
        i_agree: true,
    }
    try {
        if (cart.addressStr && dataSource.deliveryAddress) {
            dataSource.deliveryAddress.forEach((item) => {
                if (item.name === cart.addressStr) initialValues.deliveryAddressId = item.id;
            });
        }
    } catch (e) { }
    // handleSubmit

    return (
        <>
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={initialValues}>
                <div className={isMobile ? "phone-box" : ""}>
                    <DeliveryAddress dataSource={dataSource} />
                    <DeliveryDateTime form={form} dataSource={dataSource} />
                    <AdditionalInformation dataSource={dataSource} />
                    <PaymentMethod
                        form={form}
                        dataSourceContainers={dataSourceContainers}
                        dataSource={dataSource}
                        addedItems={addedItems}
                        containers={containers} />
                    <Form.Item className={"wp-100 pr-10 pl-10 mb-0"}>
                        <Title level={4}>Итого: {totalSumContainers + totalSumItem} руб.</Title>
                    </Form.Item>
                    <Form.Item name="i_agree" valuePropName="checked" className="wp-100 mb-10 pr-10 pl-10">
                        <Checkbox>
                            Я ознакомлен с графиком <a href="https://www.aqua-vista.ru/info/dostavka/" rel="noopener noreferrer" target="_blank">доставки</a>.
                        </Checkbox>
                    </Form.Item>
                </div>
                <Form.Item shouldUpdate className="wp-100 mb-0 pr-10 pl-10">
                    {() => {
                        const values = form.getFieldsValue(),
                            disabled = values.i_agree ? false : true;
                        return (
                            <Button
                                disabled={disabled}
                                block={isMobile ? true : false}
                                size={isMobile ? "large" : "default"}
                                onClick={async () => {
                                    const valid = form.validateFields()
                                    valid.then(() => {
                                        setOpened(true)
                                    })
                                }}
                                className="mutated-button">
                                Оформить заявку
                            </Button>
                        )
                    }}
                </Form.Item>
                <DialogOrder open={open} handleClose={() => setOpened(false)} form={form}>
                    <Containers
                        cart={cart}
                        dataSource={dataSourceContainers}
                        handleContainersItems={handleContainersItems}
                        addedItems={addedItems}
                        nomenclature={dataSource.nomenclature}
                        containers={containers}
                        totalSumContainers={totalSumContainers} />
                    <div className="wp-100 mb-10 mt-10 pr-10 pl-10"
                        style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            block={isMobile ? true : false}
                            size={isMobile ? "large" : "default"}
                            className="mutated-button"
                            onClick={() => {
                                setOpened(false)
                                handleSubmit(form.getFieldsValue())
                            }}
                            type="primary"
                            htmlType="submit">
                            Оформить заявку
                        </Button>
                    </div>
                </DialogOrder>
            </Form>
        </>
    )
}

const ComponentTableItems = (props) => {
    if (isMobile) {
        return (
            <div>
                <div className="phone-box pt-10">
                    <Divider>Добавленные товары</Divider>
                    <Row>
                        <Col span={24}>
                            <TableItems
                                dataSource={props.dataSource.nomenclature}
                                getItemPrice={props.getItemPrice}
                                addedItems={props.addedItems}
                                totalSumItem={props.totalSumItem}
                                deleteItem={props.deleteItem}
                                tebleAddItem={props.tebleAddItem}
                                handleChangeAmountItem={props.handleChangeAmountItem} />
                        </Col>
                        <Col className="text-center" span={24}>
                            <Link to="/products">
                                <Button className="mutated-button" type="danger" size="large">
                                    Добавить товар
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <ContainersV1
                    cart={props.cart}
                    dataSource={props.dataSourceContainers}
                    handleContainersItems={props.handleContainersItems}
                    addedItems={props.addedItems}
                    nomenclature={props.dataSource.nomenclature}
                    containers={props.containers}
                    totalSumContainers={props.totalSumContainers} />
            </div>
        )
    }
    return (
        <div>
            <Row>
                <Col span={24}>
                    <TableItems
                        dataSource={props.dataSource.nomenclature}
                        getItemPrice={props.getItemPrice}
                        addedItems={props.addedItems}
                        totalSumItem={props.totalSumItem}
                        deleteItem={props.deleteItem}
                        tebleAddItem={props.tebleAddItem}
                        handleChangeAmountItem={props.handleChangeAmountItem} />
                </Col>
                <Col className="text-center mt-25" span={24}>
                    <Link to="/products">
                        <Button className="mutated-button" type="danger" size="large">
                            Добавить товар
                        </Button>
                    </Link>
                </Col>
            </Row>
            <ContainersV1
                dataSource={props.dataSourceContainers}
                handleContainersItems={props.handleContainersItems}
                addedItems={props.addedItems}
                nomenclature={props.dataSource.nomenclature}
                totalSumContainers={props.totalSumContainers}
                containers={props.containers} />
        </div>
    )
}

const FormOrder = (props) => {
    const [open, setOpened] = useState(false)
    if (isMobile) {
        return (
            <Row>
                <Col span={24}>
                    <ComponentTableItems open={open} setOpened={setOpened} {...props} />
                </Col>
                <Col span={24}>
                    <ComponentForm open={open} setOpened={setOpened} {...props} />
                </Col>
            </Row>
        )
    }
    return (
        <Row>
            <Col span={12} className="pr-10">
                <ComponentTableItems {...props} />
            </Col>
            <Col span={12} className="pl-10">
                <ComponentForm open={open} setOpened={setOpened} {...props} />
            </Col>
        </Row>
    )
}

export default FormOrder;
