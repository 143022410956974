import React from 'react';
import {Form, Input} from 'antd';

import Grid from '@material-ui/core/Grid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import DialogTitle from "../../dialogTitle";


const DialogEdit = ({handleClose, handleSubmit, open, data, cities}) => {
    if (!open) return null;

    const [form] = Form.useForm();

    const rows = [
        // {name: "city", label: "Город", required: true, span: 12},
        {name: "street", label: "Улица", required: true, span: 12},
        {name: "house", label: "Дом", required: true, span: 6},
        {name: "apartment", label: "Квартира/Офис", required: true, span: 6},
        {name: "porch", label: "Подъезд", required: false, span: 6},
        {name: "floor", label: "Этаж", required: false, span: 6},
    ]

    const requiredRule = [{required: true, message: 'Поле обязательно для заполнения!'}];

    const getInitialValues = () => {
        let initialValues = {
            id: data.id ? data.id : 0,
            cityId: data.cityId? data.cityId: cities[0].id
        }
        rows.forEach(val => {
            if (data[val.name]) initialValues[val.name] = data[val.name]
        })

        return initialValues;
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title-address" open={open} fullWidth={true} maxWidth="xs">
            <DialogTitle onClose={handleClose} id="simple-dialog-title-address">{data.id ? "Редактирование" : "Добавление"} адреса</DialogTitle>
            <DialogContent>
                <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={getInitialValues()}>
                    <Form.Item name="id" className="none">
                        <Input type="hidden" />
                    </Form.Item>
                    <Grid xs={12} item>
                        <Form.Item label="Город" name="cityId" defaultValue={cities[0].id} value={data.cityId} rules={requiredRule}>
                            <select name="Город" defaultValue={cities[0].id} className='ant-input-lg ant-input ant-select-selector select-like-ant'>
                                {cities.map((item, idx) => {
                                    return <option selected={idx === 0} value={item.id} key={idx}>{item.name}</option>
                                })}
                            </select>
                        </Form.Item>
                    </Grid>
                    <Grid container spacing={1}>
                        {rows.map((val, idx) => (
                            <Grid key={idx} xs={val.span} item>
                                <Form.Item label={val.label} name={val.name} rules={val.required ? requiredRule : null}>
                                    <Input size="large"/>
                                </Form.Item>
                            </Grid>
                        ))}
                    </Grid>
                    <Form.Item>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="secondary"
                            size="large"
                            fullWidth={true}
                        >
                            Сохранить
                        </Button>
                    </Form.Item>
                </Form>
            </DialogContent>
        </Dialog>
    )
}
export default DialogEdit;
